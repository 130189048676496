:root {
  /* page border */
  --color0:#DFDFDF;
  /* page and navbar */
  --color1:#E5E5E5;
  /*--color1: #888888;*/
  /* sidebar #AAAAAA */
  --color2:#F4F6F9; /* #E5E5E5 */
  --color3:#DDDDDD;
  /* Left side of unothenticated */
  --color4:#FFFFFF;
  /* Right side of unothenticated */
  --color5:#F4F6F9;
  /* Button Forground Color */
  --colorButtonForground:#FFFFFF;
  /* Button Backgrounnd Color */
  --colorButtonBG:#00B012;
  /* Button Foreground Color Hover */
  --colorButtonForgroundHover:#313131;
  /* Button Color Background color Hover */
  --colorButtonHoverBG:#DAE8D3;
  /* Standard text color */
  --colorStandardText:#272727;
}

fieldset {
  text-align: left !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  top: -5px !important;
  left: 0 !important;
  margin: 0 !important;
  padding: 0 8px !important;
  pointer-events: none !important;
  border-radius: inherit !important;
  border-style: solid !important;
  border-width: 1px !important;
  overflow: hidden !important;
  min-width: 0% !important;
  border-color: rgba(0, 0, 0, 1) !important;
}

h1 {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  color: #3C582F;
}

/* p {
  margin: 5px;
} */

td {
  padding: 5px;
}

#root {
  position: sticky !important;
  /* z-index: -100 !important; */
}

.pageContainer {
  min-width: 1450px;
  display: flex;
  /*width: 100vw;*/
  flex: 1;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  background-color: var(--color2);
  position: relative !important;
  z-index: -90 !important;
}

.pageIndexContainer {
  min-width: 550px;
  display: flex;
  /*width: 100vw;*/
  flex: 1;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  background-color: white;
  position: relative !important;
  z-index: -90 !important;
}

.pageContainerLanding {
  min-width: 550px;
  display: flex;
  /*width: 100vw;*/
  flex: 1;
  height: 100%;
  min-height: 100vh;
  justify-content: center;
  background-color: var(--color2);
  position: relative !important;
  z-index: -90 !important;
}

.wrapperOuter {
  width: 100%;
  /* max-width: 1200px;  1000px */
  /* min-width: 1200px; /* 1000px */
  /*margin-top: 10px;
  margin-bottom: 10px;*/
  background-color: var(--color2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative !important;
  z-index: -80 !important;
}

.wrapperInner {
  background-color: var(--color2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative !important;
  z-index: -70 !important;
}

/* Normal page */

.contentWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative !important;
  z-index: -60 !important;
  background-color: var(--color2);
}

.contentWrapperMid {
  position: relative !important;
  z-index: -50 !important;
  background-color: var(--color2);
}

.innerContentWrapper {
  margin-top: 10px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: var(--color4);
  border: none !important;
  display: flex;
  flex-direction: column;
  width: inherit;
  padding-bottom: 20px;
  border-radius: 6px;
  position: relative !important;
  z-index: -40 !important;
}

/* Admin page */

.adminContainer {
  background-color: var(--color4);
  margin-top: 10px;
  padding: 30px;
  position: relative !important;
  z-index: -60 !important;
}

.adminInnerContainer {
  padding: 30px;  
  background-color: #F4F6F9;
  color: rgba(0, 0, 0, 0.87);
  position: relative !important;
  z-index: -40 !important;
}


/* chapterSectionStepBar CSS */

.chapterSectionStepBar {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: var(--color4);
  height: 88px;
  border-radius: 6px;
  position: relative !important;
  z-index: -30 !important;
}

.chapterSectionStepBarContainer {
  width:100%;
  margin: 2em auto; 
  position: relative !important;
  z-index: -20 !important;
}
.chapterSectionStepBarSteps {
  counter-reset: step;
}
.chapterSectionStepBarSteps li {
  list-style-type: none;
  width: calc(100% / 3);
  float: left;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: var(--colorButtonBG);
}
.chapterSectionStepBar li span {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  color:#000000;
  cursor: pointer;
}

.chapterSectionStepBarSteps li:before {
  width: 50px;
  height: 50px;
  content: counter(step);
  counter-increment: step;
  line-height: 50px;
  border: 2px solid var(--colorButtonBG) !important;
  display: block;
  text-align: center;
  margin: 0 auto .7em auto;
  border-radius: 50%;
  background-color: var(--colorButtonForground);
  cursor: pointer;
}
.chapterSectionStepBarSteps li:after {
  width: 100%;
  height: 2px;
  content: '';
  position: absolute;
  background-color: var(--colorButtonBG) !important;
  top: 26px;
  left: -50%;
  z-index: -10;
}
.chapterSectionStepBarSteps li:first-child:after {
  content: none;
}
.chapterSectionStepBarSteps li.active {
  color: var(--color5);
}
.chapterSectionStepBarSteps li.active:before {
  border-color: var(--color5);
  background-color: var(--colorButtonBG);
}
.chapterSectionStepBarSteps li.active + li:after {
  background-color: var(--colorButtonBG) !important;
}

.bottomNavbar {
  margin-left: 30px;
  margin-right: 30px;
  /* padding-top: 19px; */
  /* padding-left: 30px;
  padding-right: 30px; */
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  z-index: -50 !important;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
.flexRowAlignedCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexRowAlignedEnd {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 20px;
}
.flexColumnAlignedCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flexCentered {
  display: flex;
  justify-content: center;
}

.item {
  margin: 5px;
}
html {
  height: 100%;
}

body {
  height: 100%;
  /* min-width: 1450px; */
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px; /* identical to box height */
  color: var(--color0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color2);
}

/* Remove default upper case behaviour on buttons */

button {
  text-transform: none !important;
  padding: 15px !important;
}

.buttonDisabled {
  display: inline-flex;
  outline: 0;
  border: 0;
  margin: 0;
  color: #ffffff;
  background-color: var(--colorButtonHoverBG);
  border-radius: 6px;
  letter-spacing: 0.02857em;
  padding: 13px 20px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.buttonBorderInnerWhite {
  color: #00B012 !important;
}

.buttonBorderGreenInnerGreen {
  background-color: #00B012 !important;
  color: #FFFFFF !important;
  border: 1.5px solid #00B012 !important;
  cursor: pointer !important;
  pointer-events: auto !important;
  font-size: 16px;
  /* margin-top: 5px;
  padding-top: 6px !important;
  padding-bottom: 6px !important; */
}

.buttonBorderGreenInnerWhite {
  background-color: #FFFFFF !important;
  color: #00B012 !important;
  border: 1.5px solid #00B012 !important;
}

.buttonBorderGreenInnerLightGreen {
  background: rgba(70, 140, 37, 0.1) !important;
  color: #00B012 !important;
  border: 1.5px solid #00B012 !important;
}

.smallIconButton {
  background-color: rgb(239, 242, 246) !important;
  padding: 0px !important;
  height: 40px;
  width: 40px;
}

.unauthenticatedPaper {
  width: calc(100% - 60px) !important;
  padding-top: 1px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

.basicPaper {
  background-color: var(--color5) !important;
}

.backGroundColor0{
  background-color: var(--color0) !important;
} 

.backGroundColor1{
  background-color: var(--color1) !important;
}

.backGroundColor2{
  background-color: var(--color2) !important;
}

.backGroundColor3{
  background-color: var(--color3) !important;
}

.backGroundColor4{
  background-color: var(--color4) !important;
}

.backGroundColor5{
  background-color: var(--color5) !important;
}


.navbar {
  display: flex;
	flex-direction: row;
	justify-content: space-between;
  background-color: var(--color4);
  width: 100%;
  height: 120px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.navbarHeader {
  display: flex;
  padding: 1px;
  align-items: center;
  height: 80px;
  font-size: 8pt;
}

.navbarH1{
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 55px;
  text-transform: uppercase;
  /* identical to box height */
  color: #000000;
}

.sideMenu {
  width: 275px;
  min-width: 275px;
  /*height: 100vh;*/
  font-size: 10pt;
  background-color: var(--color4);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 10px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.sideMenuInner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sideMenuTitle {
  font-family: 'Josefin Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */
  color: #000000;
  text-transform: uppercase;
  text-align: center;
}

.sideMenuItemCurrent {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  height: 57px;

  color: #F9FAFC;
  background: #00B012;
  box-shadow: 0px 4px 50px rgba(42, 89, 152, 0.1);
}
.sideMenuItemCurrent:hover {
  color: var(--colorButtonForgroundHover);
  background: #00B012;
  box-shadow: 0px 4px 50px rgba(42, 89, 152, 0.1);
}

.sideMenuItem{
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: 24px;
  color: #898989;
  padding-left: 10px;
  padding-right: 10px;
  height: 57px;
}
.sideMenuItem:hover {
  color: var(--colorButtonForgroundHover);
  background-color: #DAE8D3;
  box-shadow: 0px 4px 50px rgba(42, 89, 152, 0.1);
}

.navMenuItem{
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  line-height: 24px;
  color: #898989;
  padding-left: 10px;
  padding-right: 10px;
  height: 37px;
  text-decoration: none;
}

.navMenuItem:hover {
  color: var(--colorButtonForgroundHover);
  /*border-bottom: 1px solid #00B012;*/
}

.navMenuItem.active {
  margin-top: 1px;
  color: var(--colorButtonForgroundHover);
  border-bottom: 2px solid #00B012;
}

.seperatorGrey{
  margin-bottom: 10px;
  border: 1px solid #E1E1E1;
}

.seperatorButtonGrey{
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #E1E1E1;
}

.standardInput{
  background: #FFFFFF;
  border: 1.5px solid #EFEFEF;
  box-sizing: border-box;
}

.footerText {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;
  color: #666666;
}

.footerContainer{
  padding-top: 35px;
  background-color: var(--color1) !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border: 3px solid green; 
}

.containerDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.containerDiv .row {
  display: table-row;
}
.containerDiv .cell {
  display: table-cell;
}
.containerDiv .topDiv {
  vertical-align: top;
}
.containerDiv .centerDiv {
  vertical-align: middle;
  justify-content: center;
  justify-self: center;
}
.containerDiv .bottomDiv {
  vertical-align: bottom;
}

.innerContainerDiv {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.innerContainerDiv .th {
  background-color: var(--color2) !important;
}
.innerContainerDiv .row {
  display: table-row;
}
.innerContainerDiv .cell {
  display: table-cell;
}
.innerContainerDiv .topDiv {
  vertical-align: top;
}
.innerContainerDiv .centerDiv {
  vertical-align: middle;
}
.innerContainerDiv .bottomDiv {
  vertical-align: bottom;
}

.superRTable tbody tr:nth-child(even){
  background-color: #FFFFFF;
}

.superRTable tbody tr:nth-child(odd){
  background-color: #F4F6F9;
}

.superRTable{
  border-spacing: 0px;
}

.superRTable thead tr th,
.superRTable tbody tr td {
  padding: 16px;
}

.buttonLink {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #898989;
}

.icon-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 15px;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.icon-box {
  display: flex;
  align-items: "flex-start";
  flex-direction: row;
  background-color: white;
  padding: 30px;
  margin-top: 30px;
}

.icons {
  height: 65px;
  width: 65px;
  min-width: 65px;
  margin-bottom: 5px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00B012;
}

.icons h3 {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */
  text-align: center;
  text-transform: uppercase;
  color: white;
}

.iconsNormal {
  background: #00B012;
  color: #FAFBFC;
  cursor: pointer;
  height: 24px;
  float: right;
  padding: 0px 0px 0px 10px;
}

.iconsNormalNew {
  /* background: #00B012; */
  color: #FAFBFC;
  cursor: pointer;
  height: 24px;
  float: right;
  padding: 0px 0px 0px 0px;
}

.iconsSmallButton {
  /* background: #00B012; */
  color: var(--color0);
  background-color: rgb(239, 242, 246);
  cursor: pointer;
  height: 24px;
  width: 24px;
  float: right;
  padding: 0px 0px 0px 0px;
}

.iconsDelete {
  padding: 10px;
  background: #BBBDC5;
  color: #FAFBFC;
  cursor: pointer;
}

.icon-rotate-45 {
  filter: progid: DXImageTransform.Microsoft.BasicImage(rotation=0.5);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  display: inline-block;
}

.buttonWithIconContainer {
  height: 24px;
  float: right;
  padding: 10px 10px 10px 15px;
  background: #00B012;
  color: #FAFBFC;
  cursor: pointer;
}

.buttonWithIconContainerNew {
  height: 48px;
  float: right;
  padding: 0px 0px 0px 0px;
  color: #FAFBFC;
  cursor: pointer;
}

.buttonWithIconContainerNew:hover {
  height: 48px;
  float: right;
  padding: 0px 0px 0px 0px;
  color: var(--colorButtonForgroundHover);
  cursor: pointer;
}

.introduction {
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 20px;
  background-color: var(--color5);
  /*border: none !important;*/
}

.introductionTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid;  
}

.introductionTitle h2{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #00B012;
}

.introductionTitle h3{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00B012;
}

.introductionContent {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  margin-left: 16px;
  margin-right: 16px;
  color: var(--colorStandardText);
}

.introductionContent h4{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--colorStandardText);
}

.end-text {
  font-style: italic;
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 15px;
}

.textInputBox {
  width: 100%;
  padding: 0px;
  background-color: white;
}

.outerContainerForInnerContent {
  padding: 30px;
  margin: 30px;

  background-color: var(--color4);
  border: none !important;
}

.outerContainerForInnerContent h3 {
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  /* identical to box height */
  color: #3C582F;
}

.outerContainerForInnerContent p {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px; /* identical to box height */
  color: #555555;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.popupCentered{
  width: 200px;
  height: 100px;
  position: fixed; 
  top: calc(50% - 50px); /* half of width */
  left: calc(50% - 100px); /* half of height */
  z-index: 41;
  border-color: #F4F6F9;
  border-width: 1px;
  border-style: solid;
  background-color: #FFFFFF;
}

.measureItem {
  display: flex;
  justify-content: flex-start;
  padding: 5px;
}

.outerContainerForAccordion {
  padding-top: 30px;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  background-color: var(--color4);
  border: none !important;
}

.accordion {
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 10px;
  background-color: var(--color4);
  /*border: none !important;*/
}

.accordionEveryOther {
  background-color: #FAFBFC;
}

.accordionSummary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  cursor: default;
}

.accordionSummaryRiskRow {
  display: flex;
  align-items: center;
}

.MuiAccordionSummary-expandIconWrapper {
  background-color: var(--colorButtonBG) !important;
  color: var(--colorButtonForground) !important;
  border-radius: 50%;
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.MuiAccordionSummary-expandIconWrapper:hover {
  background-color: var(--colorButtonHoverBG) !important;
  color: var(--colorButtonForgroundHover) !important;
  border-radius: 50%;
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  background-color: var(--colorButtonBG) !important;
  color: var(--colorButtonForground) !important;
  border-radius: 50%;
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded:hover {
  background-color: var(--colorButtonHoverBG) !important;
  color: var(--colorButtonForgroundHover) !important;
  border-radius: 50%;
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
} 

.accordionSeparator {
  margin: 30px;
}

.chapterStep {
  margin-top: 0px;
  margin-left: 30px;
  margin-right: 30px;
  padding-bottom: 10px;
  background-color: var(--color5);
  /*border: none !important;*/
}

.chapterStepTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid;
}

.chapterStepTitle h2{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #00B012;
}

.chapterStepTitle h3{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #00B012;
}

.chapterStepContent {
  margin-left: 16px;
  margin-right: 16px;
  /* border-bottom: 1px solid; */
  color: var(--colorStandardText);
}

.chapterStepContent h3{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: var(--colorStandardText);
}

.chapterStepContent h4{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: var(--colorStandardText);
}

.chapterStepContent h5{
  font-family: Manrope;
  font-style:normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 30px;
  color: var(--colorStandardText);
}

.circle {
  background-color: var(--color2);
  border: solid 2px #ffffff;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  margin: 0 auto;
  box-shadow: 0px 2px 1px #ababab;
}

.circle-chapter {
  border: solid 2px #ffffff;
  box-shadow: 0px 2px 1px #ababab;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin: 0 auto;
}

.planTable tr td{
  border-color:#E1E1E1 !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.background-ready {
  background-color: #00B012 !important;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: white;
}

.background-not-ready {
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #898989;
}

.p-content {
  text-align: left;
  padding-left: 40px;
}
.preview-preface-heading {
  margin-top: 100px;
  size: 30px;
}
.preview-preface-date {
  margin-bottom: 50px;
  size: 30px;
  font-weight: bold;
}

.previewPageContent {
  width: 100%;
  align-items: center;
}

.previewPage-background {
  text-align: left;
  padding-bottom: 20px;
}
.previewPage-background p {
  padding-left: 40px;
  padding-right: 40px;
}

.previewChapter {
  width: 100%;
}

.previewChapter ul {
  text-align: left;
  list-style-type: none;
  padding-bottom: 15px;
  padding-left: 40px;
}

.previewChapter p {
  text-align: left;
  padding-left: 40px;
  padding-right: 40px;
}

.back-info {
  text-align: left;
  padding-left: 40px;
}

.no-info {
  font-style: italic;
}

.previewChapterTable {
  border-collapse: collapse;
  table-layout: fixed;
  border-bottom: 0px;
}
.previewChapterTable th {
  font-weight: normal;
  font-size: 18px;
}

.previewChapterTable td {
  vertical-align: top;
  padding: 5px;
}

.previewChapterTable td:nth-child(1) {
  width: 75%;
  text-align: left;
}

/* Matterial UI Override CSS */

/* Accordion Summery Title */

.accordionSummary h2{
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  
  color: #00B012;
}

.sideMenuItem p {
  padding: 5px;
}


/* sectionTopPadding CSS */

.sectionTopPadding {
  margin-top: 30px;
}

.sideMenuItem p {
  padding: 5px;
}

.sectionTopPaddingWrapper {
  padding-top: 30px;
  width: 100%;
  height: 64px;
}

.sectionTopPaddingItemActive {
  cursor: pointer;
}

.sectionTopPaddingContainer {
  width: 100%;
  height: 64px;
  padding: 0;
  list-style-type: none;
  font-family: arial;
  font-family: Manrope !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  clear: both;
  line-height: 1em;
  margin: 0 -1px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sectionTopPaddingContainer li {
  float: left;
  padding-top: 21px;
  padding-bottom: 21px;
  background:var(--color5);
  color: #00B012;
  position: relative;
  /* border-top: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5); */
  margin: 0 2px;
  height: 24px;
  width: calc(100% / 3);
}

.sectionTopPaddingContainer.sectionTopPaddingItemActive li:hover {
  float: left;
  padding-top: 21px;
  padding-bottom: 21px;
  background:var(--colorButtonHoverBG);
  color: var(--colorButtonForgroundHover);
  position: relative;
  /* border-top: 1px solid var(--color5);
  border-bottom: 1px solid var(--color5); */
  margin: 0 2px;
  width: calc(100% / 3);
}

.sectionTopPaddingContainer li.active {
  color: #FFFFFF !important;
  background: var(--colorButtonBG);
}

/* Checkbox Button */

.checkBoxButtonEnabled {
  border: 1px solid #00B012 !important;
  background-color: #00B012 !important;
  color: #FFFFFF !important;
}

.checkBoxButtonDisabled {
  border: 1px solid #00B012 !important;
  background-color: white !important;
  color: #00B012 !important;
}

.checkBoxButtonFix {
  border: 1px solid #00B012 !important;
  background-color: #00B012 !important;
  color: white !important;
  cursor: pointer;
  font-size: 16px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.checkBoxButtonDoNotFix {
  border: 1px solid #00B012 !important;
  background-color: white !important;
  color: #00B012 !important;
  cursor: pointer;
  font-size: 16px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

/* End of Checkbox Button */

.checkBoxColor {
  color: var(--colorStandardText);
}

.checkBoxColor:hover{
  color: var(--colorButtonForgroundHover);
}

.checkBoxColorDisabled {
  color: rgba(186, 188, 191, 1);
}

.checkBoxButtonNoMarginOrPadding {
  padding: 0px !important;
  margin: 0px !important;
  width: fit-content !important;
}

/* PopupText */

.popupText h2 {
  font-family: Manrope !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px !important;
  /* identical to box height */
  color: #3C582F !important;
}

/* ConfirmButton */

.confirmButton {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px; /* identical to box height */
  color: var(--color0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px !important;
  border: 1px solid #00B012 !important;
  background-color: #00B012 !important;
  margin-right: 10px;
  width: 100px;
  border-radius: 6px;
}

.confirmButton:hover {
  background-color: var(--colorButtonHoverBG) !important;
  border-color: var(--colorButtonHoverBG) !important;
  color: var(--colorButtonForgroundHover) !important;
  cursor: pointer;
  border-radius: 6px;
}

/* CancelButton */

.cancelButton {
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px; /* identical to box height */
  color: #00B012 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px !important;
  border: 1px solid #00B012 !important;
  background-color: white !important;
  width: 100px;
  border-radius: 6px;
}

.cancelButton:hover {
  background-color: var(--colorButtonHoverBG) !important;
  border-color: var(--colorButtonHoverBG) !important;
  color: var(--colorButtonForgroundHover) !important;
  cursor: pointer;
  border-radius: 6px;
}

.textWithAlignedIcon {
  display: flex !important;
  align-items: center !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px;
}

.whiteSpacePreLine {
  white-space: pre-line;
}

/* Style for specific components */

.requiredFieldComponent {
  color: red;
  cursor: default;
}

/* TextField customization */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #272727 !important;
  border-width: 1px;
}


.textFieldOutlinedInput {
  border: 1.5px solid;
  box-sizing: border-box;
  color: black;
  border-color: #EFEFEF !important;
}

.textFieldLabel {
  color: rgba(0, 0, 0, 0.54) !important;
  text-decoration: none !important;
  transform: none !important;
  position: sticky;
  padding-bottom: 5px;
}

.textFieldFocused {
  font-size: inherit;
}

.notchedOutline {
  margin-top: 5px;
  color: black;
  border: none !important;
  outline: 1.5px solid !important;
  outline-color: #EFEFEF !important;
}
/* End of TextField customization */

/*Tooltip override.*/
.MuiTooltip-tooltip {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 1;
  font-size: 11pt;
  white-space: pre-line;
  border: #000000 solid 1px;
}